import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Image from 'common/src/components/Image';
import Heading from 'common/src/components/Heading';
import Select from 'common/src/components/Select';
import Container from 'common/src/components/UI/Container';
import ContactSections from '../Contact';
import FooterWrapper, { List, ListItem, BgImageWrapper } from './footer.style';
import FooterBG from 'common/src/assets/image/crypto/footer-bg.svg';
import Button from 'common/src/components/Button';

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  titleStyle,
  outlineBtnStyle,
  featureTitleStyle,
  hasNewsletter,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      cryptoJson {
        menuWidget {
          id
          title
          menuItems {
            id
            text
            url
          }
        }
        Language_NAMES {
          label
          value
        }
      }
    }
  `);

  return (
    <FooterWrapper id="footerSection">
      {hasNewsletter ? (
        <>
          <ContactSections />
          <BgImageWrapper>
            <Image src={FooterBG} alt="Footer background" />
          </BgImageWrapper>
        </>
      ) : (
        {}
      )}

      <Container noGutter mobileGutter width="1200px">
        <Box className="row mainRow" {...row}>
          <Box {...colOne}>
            <Heading content="Sprachen" {...titleStyle} />
            <Select
              options={Data.cryptoJson.Language_NAMES}
              placeholder="Deutsch"
              className="Language_search_select"
              aria-label="Language_search_input"
            />
            <Heading
              className="appDownload"
              content="Downloads"
              {...titleStyle}
            />
            <Box className="imageWrapper">
              <a href="/downloads">
                <Button
                  title="CLI Tool Releases"
                  variant="textButton"
                  iconPosition="left"
                  {...outlineBtnStyle}
                  className="btnWithoutColor"
                />
              </a>
            </Box>
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo}>
            {Data.cryptoJson.menuWidget.map((widget) => (
              <Box className="col" {...col} key={widget.id}>
                <Heading content={widget.title} {...titleStyle} />
                <List>
                  {widget.menuItems.map((item) => (
                    <ListItem key={`list__item-${item.id}`}>
                      <a className="ListItem" href={item.url}>
                        {item.text}
                      </a>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          {/* End of footer List column */}
        </Box>
        <Box className="row copyRight" {...row}>
          <Text
            content={
              <span>
                <Heading as="h3" content="circlico" {...featureTitleStyle} />
                Digitales Gründerzentrum Einstein1
                <br />
                Albert-Einstein-Straße 1<br />
                95028 Hof
                <br />
                Germany
              </span>
            }
            className="copyRightText"
          />
        </Box>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
  hasNewsletter: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-4px',
    mr: '-4px',
  },
  // Footer col one style
  colOne: {
    width: ['100%', '30%', '33%', '33%'],
    mb: ['30px', 0],
    pl: ['0px', 0],
    pr: ['0px', '0px', 0],
  },
  // Footer col two style
  colTwo: {
    width: ['100%', '70%', '67%', '67%'],
    flexBox: true,
    flexWrap: 'wrap',
  },
  // Footer col default style
  col: {
    width: ['100%', 1 / 3, 1 / 3, 1 / 3],
    pl: [0, '15px'],
    pr: [0, '15px'],
    mb: ['30px', '30px'],
  },
  // widget title default style
  titleStyle: {
    color: '#FFFFFF',
    fontSize: ['15px', '16px', '16px', '18px', '18px'],
    fontWeight: '600',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '30px'],
    fontFamily: 'Signika',
  },
  // Default logo size
  logoStyle: {
    width: '128px',
    mb: '15px',
  },
  // widget text default style
  textStyle: {
    color: '#FFFFFF',
    fontSize: '16px',
    mb: '12px',
    fontWeight: '600',
    fontFamily: 'Lato',
  },
  outlineBtnStyle: {
    minWidth: ['130px', '156px'],
    fontSize: '16px',
    fontWeight: '700',
    color: '#fff',
    p: '5px 10px',
  },
  featureTitleStyle: {
    fontSize: ['18px', '18px', '20px', '20px', '20px'],
    lineHeight: ['1', '1', '1', '1', '1'],
    fontWeight: '500',
    color: '#fff',
    letterSpacing: '-0.010em',
    mb: '10px',
  },
  hasNewsletter: {},
};

export default Footer;
